.navbar-default {
}

.navbar-hidden {
  visibility: hidden;
  opacity: 0;
  width: 100%;
  z-index: 100;
  transition: visibility 0s linear 0.5s, opacity 0.5s linear;
}
.navbar-show {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
.opacity {
  animation: opacity 1s linear;
}
@keyframes FadeIn {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}
.navbar {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background: #003333;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#747491",endColorstr="#000022",GradientType=1);
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid #f2fdff;
}
.navbar > * {
  z-index: 100;
}

.nav-container {
  position: sticky;
  width: 100dvw;
  top: 0;
  z-index: 100;
  height: 0;
}
.navbar > li {
  display: inline-block;
}
.navlink {
  color: white;
  display: block;
  text-align: center;
  padding: 1rem;
  font-size: medium;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  text-decoration: none;
}
